import { PublicationWithTriber } from '@tribuu-api';

export namespace FavoritesActions {
  export class FetchAll {
    static readonly type = '[Favorites] Fetch';
  }

  export class Add {
    static readonly type = '[Favorites] Add';
    constructor(public publication: PublicationWithTriber) {}
  }
  export class Remove {
    static readonly type = '[Favorites] Remove';
    constructor(public uid: string) {}
  }
  export class Reset {
    static readonly type = '[Favorites] Reset';
  }
}
